import React, { useState, useEffect } from "react";
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { vBox } from "gx-web-ui/utils/flex";
import max from 'lodash/max';
import min from 'lodash/min';

import FilterSelectBtnGroup from './filter/FilterSelectBtnGroup';
import FilterPriceSlider from './filter/FilterPriceSlider';
import FilterLandSizeSlider from './filter/FilterLandSizeSlider';
import RangeGroup from './filter/RangeGroup';
import CheckBoxGroup from './filter/CheckBoxGroup';
import CancelBtn from './common/CancelBtn';
import ApplyBtn from './common/ApplyBtn';
import MobileCheckBox from './common/MobileCheckBox';
import MobileFormControlLabel from './common/MobileFormControlLabel';

import './MobileLayout.less';

const MobileFilter = (props) => {
  const { propertyStore, filterStore } = props;
  const {getMapProperties_} = propertyStore;
  const {
    FILTER_BEDROOMS,
    FILTER_BATHROOMS,
    FILTER_PARKINGS,
    RENT_PRICE_RANGE,
    BUY_PRICE_RANGE,
    isRentFilter,
    rentFeatures,
    buyFeatures,
    FILTER_BUY_RENT_TYPE,
    currentFilterType,
    filterPropertyTypes,
    setCurrentFilterType,
    setShowMobileFilter,
    showMobileFilter,
  } = filterStore;

  const [filterType, setFilterType] = useState(currentFilterType);
  const isCurrentSelectedRentFilter = filterType === 'rent';
  //Price Range
  const priceSliderRange = {min:0, max:BUY_PRICE_RANGE[BUY_PRICE_RANGE.length-1].value};
  const priceRentSliderRange = {min:0, max:RENT_PRICE_RANGE[RENT_PRICE_RANGE.length-1].value};
  const [priceRange, setPriceRange] = useState([filterStore.priceRange.min||priceSliderRange.min, filterStore.priceRange.max||priceSliderRange.max]);
  // beds baths parking
  const [bedrooms, setBedrooms] = useState({ min: filterStore.bedrooms.from, max: filterStore.bedrooms.to });
  const [bathrooms, setBathrooms] = useState({ min: filterStore.bathrooms.from, max: filterStore.bathrooms.to });
  const [parkings, setParkings] = useState({ min: filterStore.parkings.from, max: filterStore.parkings.to });
  //landSize
  const landSizeSliderRange = {min:0,max:500}
  const [landSizeRange, setLandSizeRange] = useState([filterStore.landSizeRange.min||landSizeSliderRange.min,filterStore.landSizeRange.max||landSizeSliderRange.max]);
  // features 
  const featuresItems = isRentFilter?rentFeatures:buyFeatures;
  const [features, setFeatures] = useState(filterStore.features);
  //property type
  const [propertyType, setPropertyType] = useState(()=>{
    if(filterStore.propertyType.length===0){
      return []
    }else{
      return filterStore.propertyType
    }
  });
  const onClickPropertyType = (code)=> {
    if(code ==='ANY'){
      setPropertyType([code])
    }else{
      const removeAny = propertyType.filter(o=>o!=='ANY')
      if(propertyType.includes(code)){
        setPropertyType(removeAny.filter(o=>o!==code))
      }else{
        setPropertyType([...removeAny,code])
      }
    }
  };
  //additional Setting
  const [inspectionAuctionFilterChecked, setInspectionAuctionFilterChecked] = useState(filterStore.inspectionAuctionFilterChecked)
  const [excludeUnderOffer, setExcludeUnderOffer] = useState(filterStore.excludeUnderOffer)
  const [excludeOffThePlan, setExcludeOffThePlan] = useState(filterStore.excludeOffThePlan)

  useEffect(() => {
    const defaultPriceRange = isCurrentSelectedRentFilter?  priceRentSliderRange : priceSliderRange;
    setPriceRange([filterStore.priceRange.min||defaultPriceRange.min, filterStore.priceRange.max||defaultPriceRange.max])
  }, [filterType]);

  const onClose = () => {
    setShowMobileFilter(false)
  }

  const onApply = () => {
    const {
      setRentPriceRange,
      setPriceRange,
      setLandSizeRange
    } = filterStore
    const priceRangeSetter = isCurrentSelectedRentFilter?setRentPriceRange:setPriceRange
    const minPriceSize = min(priceRange)
    const maxPriceSize = max(priceRange)
    priceRangeSetter({
      min: minPriceSize === 0? null : minPriceSize,
      max: maxPriceSize === priceRange.max? null : maxPriceSize
    })
    const minLandSize = min(landSizeRange)
    const maxLandSize = max(landSizeRange)
    setLandSizeRange({
      min: minLandSize === 0? null : minLandSize,
      max: maxLandSize === landSizeSliderRange.max? null : maxLandSize
    })
    filterStore.setPropertyType(propertyType)
    filterStore.setBedrooms({from: bedrooms.min, to: bedrooms.max})
    filterStore.setBathRooms({from: bathrooms.min, to: bathrooms.max})
    filterStore.setParkings({from: parkings.min, to: parkings.max})
    filterStore.setFeatures(features)
    filterStore.setInspectionAuctionFilterChecked(inspectionAuctionFilterChecked)
    filterStore.setExcludeUnderOffer(excludeUnderOffer)
    filterStore.setExcludeOffThePlan(excludeOffThePlan)
    filterStore.setCurrentFilterType(filterType)
    getMapProperties_()
    setShowMobileFilter(false)
  }

  const clear = () => {
    console.log('clear')
    const defaultPriceRange = isCurrentSelectedRentFilter?  priceRentSliderRange : priceSliderRange;
    setPriceRange([defaultPriceRange.min,defaultPriceRange.max])
    setLandSizeRange([landSizeSliderRange.min,landSizeSliderRange.max])
    setPropertyType([])
    setBedrooms({ min: filterStore.bedrooms.from, max: filterStore.bedrooms.to })
    setBathrooms({ min: filterStore.bathrooms.from, max: filterStore.bathrooms.to })
    setParkings({ min: filterStore.parkings.from, max: filterStore.parkings.to })
    setFeatures([])
    setInspectionAuctionFilterChecked(false)
    setExcludeUnderOffer(false)
    setExcludeOffThePlan(false)
    setCurrentFilterType('newDevelopment')
  }

  return (
    showMobileFilter&&<div className="m-filters">
      <div className="filter-header">
        <div>Filters</div>
        <div onClick={clear}>Reset Filters</div>
      </div>
      <div className="filter-s-btns">
        <FilterSelectBtnGroup options={FILTER_BUY_RENT_TYPE} value={filterType} setValue={setFilterType}/>
      </div>
      <div className="filter-section">
        <div className="label">Property Types</div>
        <div style={{...vBox('start')}}>
          {
            filterPropertyTypes.map(item => {
              return <MobileFormControlLabel
                  control={<MobileCheckBox checked={propertyType.includes(item.code)} onChange={()=>onClickPropertyType(item.code)} name={item.code} />}
                  label={item.title}
              />
            })
          }
        </div>
      </div>
      <div className="filter-section">
        <div className="label">Price Range</div>
        <FilterPriceSlider
          rangeValue={priceRange}
          setRangeValue={setPriceRange}
          range={isCurrentSelectedRentFilter? priceRentSliderRange : priceSliderRange}
          step={1}
        />
      </div>
      <div className="filter-section">
        <div className="label">Bedrooms</div>
        <RangeGroup
          selectOptions={FILTER_BEDROOMS}
          range={bedrooms}
          setRange={setBedrooms}
        />
      </div>
      <div className="filter-section">
        <div className="label">Bathrooms</div>
        <RangeGroup
          selectOptions={FILTER_BATHROOMS}
          range={bathrooms}
          setRange={setBathrooms}
        />
      </div>
      <div className="filter-section">
        <div className="label">Parking</div>
        <RangeGroup
          selectOptions={FILTER_PARKINGS}
          range={parkings}
          setRange={setParkings}
        />
      </div>
      <div className="filter-section">
        <div className="label">Land Size</div>
        <FilterLandSizeSlider
          rangeValue={landSizeRange}
          setRangeValue={setLandSizeRange}
          range={landSizeSliderRange}
          step={1}
        />
      </div>
      <div className="filter-section">
        <div className="label">Features</div>
        <CheckBoxGroup
          items={featuresItems}
          values={features}
          setValues={setFeatures}
        />
      </div>
      <div className="filter-section">
        <div className="label">Others</div>
        <div style={{...vBox('start')}}>
          <MobileFormControlLabel
            control={<MobileCheckBox checked={inspectionAuctionFilterChecked} onChange={e=>setInspectionAuctionFilterChecked(e.target.checked)} name={'inspection'} />}
            label={'Inspection / auction'}
          />
          <MobileFormControlLabel
            control={<MobileCheckBox checked={excludeUnderOffer} onChange={e=>setExcludeUnderOffer(e.target.checked)} name={'excludeUnderOffer'} />}
            label={'Exclude under offer'}
          />
          <MobileFormControlLabel
            control={<MobileCheckBox checked={excludeOffThePlan} onChange={e=>setExcludeOffThePlan(e.target.checked)} name={'excludeOffThePlan'} />}
            label={'Exclude off the plan'}
          />
        </div>
      </div>
      <div className="filter-footer">
        <CancelBtn onClick={onClose} style={{marginRight:8,width:96}}>Cancel</CancelBtn>
        <ApplyBtn onClick={onApply} style={{width:96}}>See Results</ApplyBtn>
      </div>
    </div>
  )
};

export default compose(inject('filterStore', 'propertyStore'), observer)(MobileFilter);