import React from 'react'
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';

export default withStyles(theme=>({
  root:{
    marginLeft:6,
    width:'98%'
  },
  rail: {
    color: '#F5F5F5',
    opacity: 1,
    height: 4,
  },
  track:{
    color: '#E56E2C;',
    height: 4,
  },
  thumb: {
    background: '#FFFFFF',
    border: '2px solid #E56E2C'
  }
}))(Slider)
