import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

export default withStyles(theme=>({
    root: {
        color: '#D1D3D8',
        width: '16px',
        height: '16px',
        marginRight: '8px',
        '&$checked': {
            color: '#E56E2C'
        },
    },
    checked: {}
}))(props=><Checkbox color="default" {...props}/>)