import React from 'react'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles';
export default withStyles(theme=>({
  root:{
    // fontWeight:600,
    fontSize:14,
    backgroundColor:'white',
    color:'#797979',
    textTransform:'none',
  }
}))(props=><Button variant={'outlined'} {...props}/>)