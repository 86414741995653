import React from 'react'
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { withStyles,makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';


const GroupBtn = withStyles(theme=>({
  root:{
    textTransform:'none',
    whiteSpace:'nowrap',
    // borderColor:theme.palette.grey[200]
  }
}))(Button)

const BtnGroup = withStyles(theme=>({
  groupedOutlinedPrimary:{
    borderColor:'#D1D3D8'
  },
  groupedOutlinedHorizontal:{
    "&:not(:last-child)":{
      borderRightColor:'#D1D3D8',
    },
    "&:hover":{
      zIndex:2,
      borderColor:'#D1D3D8',
    }
  }
}))(ButtonGroup)

const FilterSelectBtnGroup = (props) => {
  const {
    options=[],
    value,
    setValue
  } = props
  const onClickBtn=(index)=>()=>{
    setValue(options[index].value)
  }
  const styles = useStyles()
  return (
    <BtnGroup color="primary">
      {options.map((item,index)=>
        <GroupBtn onClick={onClickBtn(index)} className={classnames({[styles.selected]:value===item.value})} style={{width:100}} key={item.title}>
          {item.title}
        </GroupBtn>
      )}
    </BtnGroup>
  )
}

const useStyles = makeStyles(theme=>({
  selected: {
    borderColor:'#E56E2C!important',
    backgroundColor: '#E56E2C!important',
    color: 'white',
    borderWidth:1,
    zIndex:2
  }
}))

export default FilterSelectBtnGroup
