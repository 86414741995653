import React from 'react'
import chunk from 'lodash/chunk'
import ceil from 'lodash/ceil'
import {makeStyles} from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import classnames from 'classnames'
import MobileCheckBox from '../common/MobileCheckBox';
import MobileFormControlLabel from '../common/MobileFormControlLabel';

const CheckBoxGroup = (props) => {
  const {items, values, setValues} = props
  const styles = useStyles()
  const handleChange=(v)=>{
    if(values.includes(v)){
      setValues([...values.filter(o=>o!==v)])
    }else{
      setValues([...values,v])
    }
  }
  return (
    <div>
      {chunk(items,ceil(items.length / 2)).map((ck,index)=>
      <FormControl component="fieldset" key={index} className={classnames({[styles.column]:index===1})}>
        <FormGroup>
          {ck.map(item=>
          <MobileFormControlLabel
            key={item.value}
            control={<MobileCheckBox checked={values.includes(item.value)} onChange={()=>handleChange(item.value)} name={item.value} />}
            label={item.title}
          />
          )}
        </FormGroup>
      </FormControl>
      )}
    </div>
  )
}
const useStyles = makeStyles(theme=>({
  column:{
    marginLeft:16
  }
}))

export default CheckBoxGroup
