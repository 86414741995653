import React from 'react'
import Slider from '../common/Slider'
import {shorterAUD} from '@src/utils/currency'
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import min from 'lodash/min'
import max from 'lodash/max'

const useStylesBlack = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

const ValueLabelComponent=(props)=>{
  const { children, open, value } = props;
  const classes = useStylesBlack()
  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={`${value} m²`} classes={classes}>
      {children}
    </Tooltip>
  );
}
  

const FilterLandSizeSlider = (props) => {
  const {rangeValue,setRangeValue,step=50,range={min:0,max:100}} = props
  const styles = useStyles()
  const valuetext=(value)=>{
    return `${value} m²`
  }
  const handleChange = (event, newValue) => {
    setRangeValue(newValue);
  };
  const rangeTitle = ()=>{
    const maxV = max(rangeValue)
    const minV = min(rangeValue)
    if(maxV !== range.max && minV===range.min){
      return `Under ${maxV} m²`
    }else if(minV!==range.min && maxV===range.max){
      return `Over ${minV} m²`//m²
    }else if(minV!==range.min && maxV!==range.max){
      return `${minV} m² - ${maxV} m²`
    }else{
      return `Any`
    }
  }
  return (
    <div>
      <Typography className={styles.title}>{rangeTitle()}</Typography>
      <Slider 
        getAriaValueText={valuetext}
        value={rangeValue}
        onChange={handleChange}
        min={range.min}
        max={range.max}
        step={step}
        ValueLabelComponent={ValueLabelComponent}
      />
    </div>
  )
}

const useStyles = makeStyles((theme)=>({
  title:{
    fontWeight:500,
    color: '#797979'
  }
}))

export default FilterLandSizeSlider;
