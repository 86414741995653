import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default withStyles(theme=>({
  root: {
    marginBottom: '8px',
    marginLeft: 0,
    marginRight: 0,
  },
  label: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.85)'
  }
}))(props=><FormControlLabel color="default" {...props}/>)